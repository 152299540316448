import React, { useEffect } from "react";
import { Jumbotron, Container, Row } from "react-bootstrap";
import "./HeroSection.css";

function doAnimate(id, delay) {
  var element = document.getElementById(id);
  element.animate(
    [
      { opacity: "0", transform: "translateY(20px)" },
      { opacity: "1", transform: "translateY(0)" },
    ],
    {
      duration: 500,
      delay: delay,
      fill: "forwards",
    }
  );
}

function HeroSection() {
  useEffect(() => {
    var title = document.getElementById("hero-title");
    title.animate(
      [
        { opacity: "0", transform: "translateY(-20px)" },
        { opacity: "1", transform: "translateY(0)" },
      ],
      {
        duration: 500,
        delay: 200,
        fill: "forwards",
      }
    );
    doAnimate("hero-subtitle", 200);
    doAnimate("hero-button-0", 400);
    doAnimate("hero-button-1", 400);
    doAnimate("hero-button-2", 400);
  }, []);

  return (
    <div id="home" className="hero-container">
      <div className="hero-bg">
        <div className="hero-text">
          <h1 id="hero-title" className="hero-title opacity-zero">
            Hi, I'm Danny Fung
          </h1>
          <h4 id="hero-subtitle" className="assisting-text opacity-zero">
          Full Stack Engineer
          </h4>
          <Row className="hero-links">
            <a href="mailto:dannyfung97@gmail.com">
              <button id='hero-button-0' className="button hero-button opacity-zero">
                <span className="icon">
                  <i className="fas fa-envelope fa-lg"></i>
                </span>
              </button>
            </a>
            <a href="https://github.com/DannyFung97" target="_blank">
              <button id='hero-button-1' className="button hero-button opacity-zero">
                <span className="icon">
                  <i className="fab fa-github fa-lg"></i>
                </span>
              </button>
            </a>
            <a href="https://www.linkedin.com/in/danny-fung/" target="_blank">
              <button id='hero-button-2' className="button hero-button opacity-zero">
                <span className="icon">
                  <i className="fab fa-linkedin fa-lg"></i>
                </span>
              </button>
            </a>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default HeroSection;
